<template>
  <div>
    <div class="work-container" id="myworks">
      <h1>风写过日记，留下盛夏的字迹</h1>
      <h1>被风带走的纸张，找不回被时间带走的记忆</h1>
      <iframe
        src="//player.bilibili.com/player.html?isOutside=true&aid=1752306520&bvid=BV11x421Q7dW&cid=1486471632&p=1&autoplay=0"
        width="50%"
        height="80%"
        scrolling="no"
        frameborder="0"
        class="bilibili-player"
      ></iframe>
    </div>
    <div class="school-container" id="myschool">
      <H2 @click="changeText">{{ text }}</H2>
      <img src="../get/校园.jpg" alt="快跑QAQ" />
      <div class="school-text">{{ text2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      text: "广理工学院，一个养老和学习都还算不错的地方",
      text2: ''
    }
  },

  methods: {
    changeText () {
      if (this.text2 === '') {
        this.text = "恭喜你发现了彩蛋,骗你的";
        this.text2 = "快跑QAQ!!";
      }
      else {
        this.text = "广理工学院，一个养老和学习都还算不错的地方";
        this.text2 = ""
      }
    }
  }
}

</script>

<style>
@font-face {
  font-family: "刀体";
  src: url(../get/AlimamaDaoLiTi.ttf);
}
@font-face {
  font-family: "叶体";
  src: url(../get/新叶念体.ttf);
}
.work-container {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  border-radius: 10px;
}
.school-container {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 70vh;
  border-radius: 10px;
}
iframe {
  margin: auto;
  width: 80%;
  height: 80%;
}
h1 {
  font-size: clamp(2rem, 5vw, 3rem);
  font-family: "叶体";
}
img {
  width: 60%;
  height: 60%;
  object-fit: cover;
  transition: all 5s ease;
}
.school-container img:hover {
  transform: skewX(3turn);
}
.school-container h2:hover {
  transition: all 0.5s ease;
}
</style>