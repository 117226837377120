<template>
  <div>
    <!-- 这里是主题盒模型 -->
    <div class="theme-box" id="about">
      <div class="introduce">
        <div class="head-img">
          <img src="../get/头像.png" />
          <span class="Body-head-name">{{ name }}</span>
        </div>
        <div class="content-title">{{ t1 }}</div>
        <div class="content">{{ content1 }}</div>
        <div class="content">{{ content2 }}</div>
        <div class="content-title">{{ t2 }}</div>
        <div class="content">{{ content3 }}</div>
        <div class="content">{{ content4 }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      name: '2004pxy',
      t1: '关于本站作者：',
      content1: '我是一名大二计算机学生，热爱前端开发，游戏开发，喜欢音乐，希望能与你交流学习。',
      content2: '同时我也是一个编曲爱好者，动漫爱好者，游戏爱好者。',
      t2: '关于本站：',
      content3: '本站是一个基于vue框架开发的个人博客，主要分享编曲，游戏，音乐，动漫，生活等方面的内容，分享个人的学习心得，分享生活的点滴。',
      content4: '纯CSS手搓，希望你能喜欢！'
    }
  }

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "刀体";
  src: url(../get/AlimamaDaoLiTi.ttf);
}
@font-face {
  font-family: "叶体";
  src: url(../get/新叶念体.ttf);
}
.introduce {
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 10vh auto;
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border: clamp(2px, 0.5vw, 5px) solid rgba(16, 236, 206, 0.904);
  transition: background-color 1s ease-in-out;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
}
.introduce:hover {
  transition: background-color 1s ease, color 1s ease;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
.Body-head-name {
  font-family: "刀体";
  color: rgba(16, 236, 206, 0.904);
  font-weight: bold;
  font-size: clamp(2rem, 5vw, 3rem);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 10px;
  text-shadow: 20px 20px 60px #949494, -20px -20px 60px #ffffff;
}
.head-img {
  position: relative;
  height: 20vh;
  width: 20vh;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  top: -5%;
}
.head-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.theme-box {
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20vh;
  display: flex;
  flex-direction: column;
  height: 150vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.content {
  text-justify: inter-ideograph;
  font-size: clamp(2rem, 3vw, 3rem);
  font-family: "叶体";
  justify-content: center;
  transition: color 1s ease-in-out;
}
.content-title {
  font-weight: bold;
  text-justify: inter-ideograph;
  font-size: clamp(2rem, 3vw, 3rem);
  font-family: "刀体";
  justify-content: center;
  color: rgb(15, 29, 218);
}
</style>