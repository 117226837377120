<template>
  <div>
    <div class="container" id="header">
      <div class="header-title"><span>2004pxy的小站</span></div>

      <video src="../get/二次元背景.mp4" autoplay loop muted></video>
      <div class="header-fixed">
        <nav>
          <ul class="navbar">
            <li>
              <div class="header-icon">
                <span>2004pxy的小站</span>
              </div>
            </li>
            <li herf="#header" @click="HeaderFuction">首页</li>
            <li herf="#about" @click="AboutFuction">关于本站</li>
            <li herf="#myworks " @click="myWorksFuction">我的作品</li>
            <li herf="#myschool" @click="mySchoolFuction">我的学校</li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      height: 0,
      scrollY: 0,
    }
  },
  methods: {
    HeaderFuction: function () {
      window.location.href = "#header";
    },
    AboutFuction: function () {
      window.location.href = "#about";
    },
    myWorksFuction: function () {
      window.location.href = "#myworks";
    },
    mySchoolFuction: function () {
      window.location.href = "#myschool";
    },
  },
}


</script>
<style>
@font-face {
  font-family: "刀体";
  src: url(../get/AlimamaDaoLiTi.ttf);
}
@font-face {
  font-family: "叶体";
  src: url(../get/新叶念体.ttf);
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  z-index: -1;
}
.header-icon {
  font-size: Clamp(0.5rem, 0.5rem + 3.64vw, 3rem);
  text-align: center;
}
.header-title {
  position: relative;
  font-family: "叶体";
  color: skyblue;
  margin: auto auto;
  font-size: clamp(3rem, 1rem + 3.64vw, 7rem);
  text-align: center;
  animation-name: color-change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  text-decoration-thickness: 3px;
  text-shadow: #949494;
  width: 70vw;
}
.header-fixed {
  position: fixed;
  float: left;
  display: flex;
  flex-direction: column;
  height: 10vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.3rem solid rgba(255, 255, 255, 0.7);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 2;
}

.navbar {
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
}

li {
  list-style-type: none;
  flex-grow: 1;
  font-size: clamp(0.5rem, 3.5vw, 2rem);
  line-height: 5vh;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: #f2f2f2;
  font-family: "刀体";
  border-left: #f2f2f2 1px solid;
  transition: all 1.2s ease;
}
li:hover:not(:nth-child(1)) {
  transition: background-color 1s ease, color 0.8s ease 0.5s;
  border-left: #f2f2f2 1px solid;
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 10vh;
  box-shadow: 10px 10px 60px #949494, -10px -10px 60px #ffffff;
}
.container {
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.3rem solid rgba(255, 255, 255, 0.7);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@keyframes color-change {
  0% {
    color: red;
  }
  20% {
    color: rgb(136, 216, 23);
  }
  30% {
    color: rgb(21, 124, 214);
  }
  40% {
    color: palegreen;
  }
  80% {
    color: bisque;
  }
  100% {
    color: white;
  }
}
</style>