<template>
  <div>
    <myHeader> <el-button v-if="isshow">点我</el-button></myHeader>
    <myBody></myBody>
    <MyFooter></MyFooter>
  </div>
</template>

<script>
import myHeader from './components/myHeader';
import myBody from './components/myBody';
import { Button } from 'element-ui';
import MyBody from './components/myBody.vue';
import MyFooter from './components/myFooter.vue';
export default {
  components: {
    'myHeader': myHeader,
    'myBody': myBody,
    'el-button': Button,
    'MyFooter': MyFooter,
    MyBody
  },
  data () {
    return {
      ishow: false
    }
  }
};
</script>

<style>
</style>